<!-- 历史版本详情 -->
<template>
  <div class="do-page-versions-details">
    <base-nav-bar @click-left="router.go(-1)" left-arrow title="E客流 v 1.0">
    </base-nav-bar>
    <base-bscroll :safeareaNavbar="true" :options="{ bounce: true }">
      <div class="do-page-versions-details-content">
        <div class="list">
          <div class="title">E客流 v 1.0</div>
          <div class="time">2022.01.20</div>
          <div class="content">
            <div class="item">1. 优化产品体验 xxx</div>
            <div class="item">2. 修复若干问题</div>
            <div class="item">1. 优化产品体验 xxx</div>
            <div class="item">2. 修复若干问题</div>
            <div class="item">1. 优化产品体验 xxx</div>
            <div class="item">2. 修复若干问题</div>
            <div class="item">1. 优化产品体验 xxx</div>
            <div class="item">2. 修复若干问题</div>
          </div>
        </div>
      </div>
    </base-bscroll>
  </div>
</template>
<script setup>
import { useRouter } from "vue-router";

const router = useRouter();

console.log("router", router);
</script>

<style lang="scss">
.do-page-versions-details {
  background: #ffffff;

  .do-com-navbar {
    &::after {
      border-bottom-width: 0px !important;
    }
  }
  .do-com-scroll-wrapper {
    background: #ffffff;
  }
  &-content {
    padding: 0 0.5rem !important;
    .list {
      padding-top: 0.42rem;
      .title {
        font-size: 0.3rem;
        font-weight: 500;
      }

      .more-btn {
        text-decoration: underline;
        font-size: 0.28rem;
        font-weight: 400;
        color: #0057ff;
      }
      .time {
        font-size: 0.24rem;
        font-weight: 400;
        color: rgba(2, 4, 15, 0.25);
      }

      .content {
        margin-top: 0.24rem;
        padding-bottom: 0.4rem;
        font-size: 0.28rem;
        font-weight: 400;
        color: rgba(2, 4, 15, 0.65);
        padding-top: 0.22rem;
        .item {
          margin-bottom: 0.04rem;
        }
      }
    }
  }
}
</style>
